<template>
    <v-timeline :side="getSide()">
        <v-timeline-item
                dot-color="deep-purple-lighten-1"
                v-for="(work, i) in works"
                :key="i"
                small
        >
            <template v-slot:opposite>
        <span
                :class="`headline font-weight-bold`"
                v-text="work.yearMonth"
        ></span>
            </template>
            <div class="py-4" style="padding-left:10px">
                <h2 :class="`headline font-weight-light mb-4`">{{work.name}}</h2>
                <div style="color: #67656b">
                    &nbsp; &nbsp; &nbsp; &nbsp;{{work.content}}
                </div>
                <v-chip v-for="(tag, i) in work.tags"
                        :key="i"
                        class="ma-2"
                        :color="colors[i]"
                        label
                >
                    {{tag}}
                </v-chip>
                <v-alert  style="margin-top: 5px"
                        border="right"
                        border-color="success"
                        elevation="2"
                >
                    <span style="color: #794b6a; font-size: 14px">
                        {{work.framework}}
                    </span>
                    <div style="margin-top: 10px; font-size: 14px">
                        主要工作和业绩：
                    </div>
                    <ul style="color: #4355a7; font-size: 14px; ">
                        <li style="margin-top: 5px" v-for="(item, i) in work.lists"
                            :key="i">
                           ● {{item}}
                        </li>
                    </ul>
                </v-alert>
            </div>
        </v-timeline-item>
    </v-timeline>
</template>


<script>
    export default {
        data() {
            return {
                colors:['primary', 'pink',  'green', 'red', 'orange',
                    'default', 'primary', 'pink',  'green', 'red' ,'orange'],
                works:[
                    {
                        name: '香港航空帆软填报项目',
                        content: '基于帆软Report产品，开发旅客出票数据流程的填报表单，并集成微软Microsoft Azure单点登录，然后基于Docker容器部署帆软Report到客户的环境。',
                        yearMonth: '2023-03',
                        tags:['Fine Report','Docker','阿里云','Microsoft Azure','Auth2'],
                        framework:'基于FineReport产品做的填报项目，业务本身不复杂，难点在于集成了Microsoft Azure 并运行在Docker容器中，开发测试部署在Portainer，生产环境部署在阿里云。',
                        lists:[
                            '制作最新版本FineReport的Docker镜像（因官方没有提供，只能自给自足）。',
                            'FineReport产品源码级改造，FrFilter过滤器中集成了Microsoft Azure 单点登录。',
                            'FineReport开发测试环境部署到Portainer。',
                            'FineReport生产环境部署到阿里云企业级分布式应用服务 EDAS中。'
                        ]
                    },
                    {
                        name: '一汽大众 - 财务驾驶舱BI',
                        content: '对项目一期进行升级改造，对技术架构升级。业务方面，各大主题指标的数据模型及可视化开发，实现决策移动化、掌控全局化、预警智能化，支撑领导的决策依据，助力业务人员各大应用场景分析。',
                        yearMonth: '2022-11',
                        tags:['SpringCloud Gateway','OpenFeign','JWT', 'Auth2', '华为云', 'Tableau'],
                        framework:'技术架构：开发架构为 SpringCloud  生态，包括 SpringCloud Gateway 、OpenFeign、JWT、Auth2 等，部署服务基于华为云 HCSOnline 的云容器引擎 Cloud Container Engine。',
                        lists:[
                            '对财务BI一期升级，传统Web项目改为微服务架构，数据迁移等工作。',
                            '业务模块开发，Tableau图片扩展插件后台及接口、日志埋点接口等。',
                            '项目日常维护，各类漏洞修复。',
                            'HCSOnline流水线部署，接入IAM单点登录，编写终验文档。'
                        ]
                    },
                    {
                        name: '一汽大众 - 奥迪CarlineBI',
                        content: 'Carline BI系统整合产品规划、销售和财务数据信息。通过生命周期、运营两大视角开展可视化分析，并可下钻到明细，展示形式包括但不限于卡片、趋势图、柱形图、环形图、报表、地图、瀑布图等。',
                        yearMonth: '2022-09',
                        tags:['SpringCloud Gateway','OpenFeign','JWT', 'Auth2', '华为云', 'Tableau'],
                        framework:'技术架构：开发架构为 SpringCloud  生态，包括 SpringCloud Gateway 、OpenFeign、JWT、Auth2 等，部署服务基于华为云 HCSOnline 的云容器引擎 Cloud Container Engine。',
                        lists:[
                            '业务模块开发，包括边际贡献总览、车型绩效总览、收入管理分析、对比等。',
                            '项目日常维护，各类漏洞修复。',
                            'HCSOnline流水线部署，接入IAM单点登录，编写终验文档。'
                        ]
                    },
                    {
                        name: '一汽大众 - 供应链洞察指挥中心BI平台',
                        content: '供应链洞察指挥中心（Supply-chain Insight Center，简称Dig Center）致力于为3.0订单到交付业务穿透式管理提供有力支撑，实现横向到底、纵向到边、竞争生态、迭代进化的全领域管理体系提升，支撑订单到交付流程绩效的持续高速改善。',
                        yearMonth: '2022-04',
                        tags:['SpringCloud Gateway','OpenFeign','JWT', 'Auth2', '华为云', 'Tableau'],
                        framework:'技术架构：开发架构为 SpringCloud  生态，包括 SpringCloud Gateway 、OpenFeign、JWT、Auth2 等，部署服务基于华为云 HCSOnline 的云容器引擎 Cloud Container Engine。',
                        lists:[
                            'BI平台接入统一身份认证平台，开发整车订单明细、订单旅程交期、零件滞留大屏等接口。',
                            '编写业务API接口鉴权功能，包括过滤器处理和角色接口配置。',
                            'SonarQube、FindBugs、系统渗透测试、绿盟安全评测报告等全部代码安全或漏洞修复。',
                            'HCSOnline 流水线部署，包括：获取源码、漏洞扫描、打包编译、镜像推送、容器部署。',
                            '编写终验文档，包括架构设计、接口文档、数据库文档、测试方案和计划、部署文档等。'
                        ]
                    },
                    {
                        name: '一汽集团 - 会议数智化BI平台',
                        content: '会议数智化BI平台是在集团总部的大数据，商业智能下的高层例会系统，主要给高层各部门领导提供周、月、季例会的业务数据可视化支撑，更好的提升例会效率、会议质量。',
                        yearMonth: '2022-07',
                        tags:['SpringCloud Gateway','Nacos','Tableau','KubeSphere', 'Tableau','Vue'],
                        framework:'技术架构：基于 SpringCloud Alibaba 生态，配置中心为 Nacos，镜像管理使用 Harbor，容器托管服务使用 KubeSphere。',
                        lists:[
                            '老系统升级改造，将传统Web系统改造为微服务系统。',
                            'BI平台接入Tableau Server ，开发会议计时器、会议版本菜单指标状态功能等。',
                            '会议版本菜单集成钉钉宜搭流程，自动拉取最新版本数据，修复各类漏洞。',
                            'Jenkins 流水线升级改造，迁移到一汽云工作台 DevOps 中。'
                        ]
                    },
                    {
                        name: '爱看书免费小说',
                        content: '免费手机阅读APP，百万级DAU，所有内容均为正版并提供免费阅读服务，收益主要为广告和会员。',
                        yearMonth: '2020',
                        tags:['SpringCloud Config','Eureka','Hystrix','OpenFeign','RabbitMQ','ElasticSearch'],
                        framework:'技术架构：基于 SpringCloud 生态，包括 SpringConfig、Eureka、Hystrix、Feign 等组件的应用，按业务拆分的服务主要有用户中心、会员中心、支付中心、阅读中心、云书架服务、搜索中心、精品页服务、排行榜服务、评论服务、积分服务、福利任务等。',
                        lists:[
                            'APP全部功能微服务拆分改造，并上云，使得业务服务更好的支持DevOps模式。',
                            '书籍内容从Redis中迁移到CDN，上线后大量减少容器实例数量，解决 Redis 请求超时的情况。',
                            '云书架服务从0到1，设计及开发，并稳定上线。',
                            '支付中心VIP会员策略改造，不同类型人群显示不同的优惠套餐，大幅度提升会员的收益。',
                            '设计及开发图书信息定时刷新的方式改为基于`RabbitMQ`异步处理，使得业务部分图书内容、缓存的更新达到准实时状态。'
                        ]
                    },
                    {
                        name: '爱看书极速版',
                        content: '免费手机阅读APP，所有内容均为正版并提供免费阅读服务，收益主要为广告和会员。',
                        yearMonth: '2020',
                        tags:['SpringCloud Config','Eureka','Hystrix','OpenFeign','RabbitMQ','ElasticSearch'],
                        framework:'技术架构：是看书免费小说的一个衍生版本APP，整体技术架构基本一致。',
                        lists:['负责APP版本迭代开发，提供服务接口给Android客户端使用。','配合推广同学接入广告API等']
                    },
                    {
                        name: '中文书城',
                        content: '中文书城是一款主打让快乐阅读无处不在的小说阅读APP，与免费版不同的是没有广告，为收费阅读。包括VIP包月包年和收费章节2种模式。涉及端有 Android、iOS、PC、H5。',
                        yearMonth: '2019',
                        tags:['SpringCloud Config','Eureka','Hystrix','OpenFeign','ElasticSearch'],
                        framework:'技术架构：APP端的技术架构基本、服务拆分粒度和爱看书差不多。PC站基于Dubbo、Zookeeper开发。',
                        lists:[
                            '听书功能从0到1，设计及开发并稳定上线。',
                            '中文书城PC站，阅读内容改造，由原来的查库改为接入基础平台的微服务接口。',
                            '优化APP的微信支付、支付宝支付、华为支付相关接口，将购买和转化VIP整合到一个业务逻辑中，使得与客户端减少一次交互，提升稳定性。'
                        ]
                    },
                    {
                        name: '能加空间',
                        content: '以空间 + 服务 + 社群为模式基础，为企业提供新型办公环境。主要包括分店管理、工位管理、入驻团队、客户管理、合同管理、场地管理、门禁管理、财务管理、统计报表等等，其中入驻会员可使用微信小程序、公众号进行扫码开门、预约会议室等',
                        yearMonth: '2018',
                        tags:['SpringBoot','MyBatis','Shiro','MySQL','ActiveMQ'],
                        framework:'技术架构：基于SpringBoot、Mybatis、Shiro、MySQL、ActiveMQ。',
                        lists:[
                            '3天内完成学习微信小程序开发，并培训给团队成员，效率的知识传递节省开发成本；',
                            'Nginx 集群部署，整合Shiro开发的登录、授权功能，采用Redis统一存储Session、权限；',
                            '协助项目经理在项目计划时间内完成了功能模块开发、测试、及项目上线试运行，获得公司嘉奖。'
                        ]
                    },
                    {
                        name: '首农集团云管控平台',
                        content: '首都农业集团云管控平台项目，项目涉及到多个企业级系统，包括中软R1、致远OA、`BI`报表、数据直报系统、电子档案管理系统、门户系统的开发与实施。',
                        yearMonth: '2017',
                        tags:['中软R1','致远OA','BPM','门户','WebService','JQuery'],
                        framework:'本项目包含多个项目，由二次开发和成熟产品，负责各个产品集成在企业门户中应用。',
                        lists:[
                            '开发门户系统基于R1的单点登录，开发组织架构扩展接口程序，实现对组织写操作的数据同步；',
                            '负责三方商家的协调沟通，组织会议，顺利的解决了三方商家沟通障碍问题，促进项目的正常进行；',
                            '开发电子档案系统，`WebService`接口供致远OA流程归档调用，将流程及附件存储到档案系统中；',
                            '规定时间内协助项目经理完成项目功能模块开发、及项目上线试运行，获得公司嘉奖。'
                        ]
                    },
                    {
                        name: '北京星网锐捷BPM项目',
                        content: '锐捷网络BPM项目，基于联科BPM基础上创建业务流程、二次开发等。',
                        yearMonth: '2016',
                        tags:['BPM','门户','WebService','JQuery','EasyUI'],
                        framework:'使用联科BPM，以及WebService开发项目',
                        lists:[
                            '带领2个开发工程师在甲方开发和实施BPM项目。',
                            'BPM平台接入甲方的单点登录系统。'
                        ]
                    },
                    {
                        name: '北京金泰集团OA项目',
                        content: '金泰集团以及旗下15个分公司的OA自动化办公系统开发和运维。包括电子邮件、在线消息、收文流程、发文流程、业务流程、远程公文收发、电子档案、新闻公告等功能模块开发。',
                        yearMonth: '2015',
                        tags:['BPM','门户','WebService','JQuery','EasyUI'],
                        framework:'基于联科BPM基础上，二次开发各个功能模块。',
                        lists:[
                            '组织开发工程师开发各个模块并对功能测试反馈，到最终验收完成。',
                            '开发电子邮件功能、在线短信、远程公文收发（基于WebService）。',
                            '开发门个人工作台页面功能'
                        ]
                    },
                    {
                        name: '北京安泰伟奥BPM项目',
                        content: '安泰伟奥的住房公积金管理中心软件，嵌入联科BPM以提供可在线定义工作流的功能。',
                        yearMonth: '2014',
                        tags:['BPM','门户','WebService','JQuery','EasyUI'],
                        framework:'基于联科BPM，二次开发以及定制客户所需的工作流WebService接口提供给客户住房公积金软件使用，让其使用自己的UI页面表单，基于BPM的流程流转。',
                        lists:[
                            '定制开发工作流核心接口，包括启动流程、获取待办列表、获取流程实例、提交流程实例等接口。',
                            '组织架构同步开发，BPM组织架构定时拉取住房公积金组织数据，实现增量同步。',
                            '给客户培训BPM流程的使用和二次开发。'
                        ]
                    },
                ],
            }
        },
        created() {

        },
        computed:{
            getSide() {
                return function () {
                    if (this.isMobile()){
                        return "end";
                    }
                    return "";
                };
            },
        },
        methods: {
            isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return flag;
            }
        }
    }
</script>