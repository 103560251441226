<template>
  <v-app>
    <v-main>
      <div>
        <component v-bind:is="currentComponent">
        </component>
      </div>

      <v-bottom-navigation
              :value="activeBtn"
              color="primary"
              horizontal
              grow
      >
        <v-btn @click="loadContent('project')">
          <span style="font-size:16px">作品信息</span>
        </v-btn>

        <v-btn @click="loadContent('company')">
          <span style="font-size:16px">公司履历</span>
        </v-btn>
      </v-bottom-navigation>
    </v-main>
  </v-app>
</template>

<script>
import project from './pages/project.vue'
import company from './pages/company.vue'

export default {
  name: 'App',

  components: {
    project,
    company
  },

  data: () => ({
    currentComponent: 'project',
    activeBtn: 1
  }),

  methods: {
      loadContent(name){
        this.currentComponent = name
      }
  }
}
</script>
