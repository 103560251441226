import VueRouter from 'vue-router'

// 页面组件
const project = (resolve) => require(['@/pages/project.vue'], resolve)
const company = (resolve) => require(['@/pages/company.vue'], resolve)
// 路由配置
const routes = [
    {
        path: '',
        name: 'project',
        component: project
    },
    {
        path: '/company',
        name: 'company',
        component: company
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router