<template>
    <div class="d-flex align-center flex-column" >
        <v-card :width="getWidth()" v-for="(company, i) in list"
                :key="i" style="margin-top: 30px">
            <v-card-item >
                <v-card-title >{{company.name}}</v-card-title>
                <v-card-subtitle>{{company.times}}</v-card-subtitle>
            </v-card-item>
            <v-card-text>
                <p>{{company.job}}</p>
                {{company.content}}
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                list:[
                    {
                        name:'深圳市优阅达数据科技有限公司',
                        job:'交付中心Java架构',
                        times:'2022年3月 ~ 至今',
                        content:'负责一汽大众、一汽集团各个项目的业务开发、流水线部署、交付验收、编写技术文档、漏洞修复等工作。'
                    },
                    {
                        name:'北京中文万维科技有限公司',
                        job:'阅读部Java架构',
                        times:'2019年2月 ~ 2022年3月',
                        content:'负责对爱看书APP产品迭代开发、服务端技术升级改造、编写技术文档，提升接口性能和稳定性，实现AWS服务器降费，获得3次优秀员工。'
                    },
                    {
                        name:'北京元霆数据有限公司',
                        job:'技术经理',
                        times:'2016年5月 ~ 2019年1月',
                        content:'配合项目经理对产品功能需求分析，业务逻辑技术选型、数据库设计和开发工作，编写开发文档等。'
                    },
                    {
                        name:'北京云悦软件有限公司',
                        job:'开发工程师',
                        times:'2012年1月 ~ 2016年4月',
                        content:'负责对BPM业务流程管理系统的部署、模块开发、日常维护等工作。'
                    }
                ]
            }
        },
        created() {

        },
        computed:{
            getWidth() {
                return function () {
                    if (this.isMobile()){
                        return "400";
                    }
                    return "800";
                };
            },
        },
        methods: {
            isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return flag;
            }
        }
    }
</script>